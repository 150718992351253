<template>
    <app-dialog-info
        class="app-dialog-remove-service-provider"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Service Provider removal</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <div class="wrap">
                <app-error v-model="error" :message="error" />

                <div class="content">
                    <p>You have selected to remove "{{ service_provider_name }}".</p>
                    <p>If this was the action that you want to do, please confirm your choice or cancel and return to the page.</p>
                </div>

                <div class="btns">
                    <button class="btn btn-primary btn-left" @click="confirm">Confirm</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:    { required: true                       },
        maxWidth: { type: [Number, String], default: 610 },

        uuid:     { type: String, default: null          },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
    },

    data() {
        return {
            loading: false,

            error: null,
        }
    },

    methods: {
        confirm() {
            this.error = null
            this.loading = true

            this.$store.dispatch('api_serviceprovider/DeleteServiceProvider', { UUID: this.uuid })
                .then(() => {
                    this.$emit('confirm')
                    this.loading = false
                })
                .catch(error => {
                    this.error = errMessage(error)
                    this.loading = false
                })
        },

        close() {
            this.$emit('close')
        },
    },

    computed: {
        ...mapGetters([
            'available_service_providers',
        ]),

        service_provider_name() {
            const service_providers = this.available_service_providers.reduce((obj, item) => {
                obj[item.UUID] = { ...item }
                return obj
            }, {})

            return service_providers[this.uuid].Name
        },

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-remove-service-provider {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;
        }

        p {
            font-weight: normal;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-remove-service-provider {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>