<template>
    <div class="page-providers container">
        <div class="row">
            <div class="col-12">
                <app-table
                    class="providers-table"

                    :sort-value="filter.sort"
                    :sort-options="sort"
                    @sort-change="onSortChange"

                    title="Providers"

                    :clickable="true"

                    :cols="cols"
                    :rows="providers"

                    :loading="loading"

                    ref="providers"

                    @row-click="onProvidersRowClick"
                    @row-click-mobile="onProvidersRowClick"
                    @remove-service-provider="showRemoveServiceProviderDialog"
                >
                    <template v-if="allow_create_provider" #header-actions>
                        <router-link :to=" {name: 'provider-create' }" class="btn btn-small btn-primary">Add Provider</router-link>
                    </template>
                </app-table>

                <app-pagination
                    v-if="pagination.total"

                    v-model="pagination.page"

                    :total="pagination.total"
                    :limit="pagination.limit"
                    :page-range="pagination.range"

                    @change="onPageChange"
                />
            </div>
        </div>

        <app-dialog-remove-service-provider
            v-if="dialogs.provider.remove.show"
            v-model="dialogs.provider.remove.show"

            :uuid="dialogs.provider.remove.uuid"

            @confirm="onDialogRemoveServiceProviderConfirm"
            @close="onDialogRemoveServiceProviderClose"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import appDialogRemoveServiceProvider from './components/app-dialog-remove-service-provider'

export default {
    props: {
        search: { type: Object, default: () => ({ word: '', option: null })}
    },

    components: {
        appTable,
        appPagination,

        appDialogRemoveServiceProvider,
    },

    data() {
        return {
            providers_list: [],

            filter: {
                word: '',
                previous: '',
                sort: {
                    field: 'SPID',
                    order: 'asc',
                },
            },

            sort: [
                { field: 'SPID',  title: 'SPID'  },
                { field: 'Name',  title: 'Name'  },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,

            dialogs: {
                provider: {
                    remove: {
                        show: false,

                        uuid: null,
                    },
                },
            },
        }
    },

    methods: {
        showRemoveServiceProviderDialog(row) {
            this.dialogs.provider.remove.show = true
            this.dialogs.provider.remove.uuid = row.uuid
        },
        onDialogRemoveServiceProviderConfirm() {
            this.dialogs.provider.remove.show = false
            this.dialogs.provider.remove.uuid = null

            this.getProviders()
        },
        onDialogRemoveServiceProviderClose() {
            this.dialogs.provider.remove.show = false
            this.dialogs.provider.remove.uuid = null
        },

        init(to, from) {
            const getProviders = () => { this.getProviders(to.params.page) }

            if (from.name && /^provider(-|$)/i.test(from.name)) {
                this.loadProvidersFilter().then(getProviders).catch(getProviders)
            } else {
                this.$store.dispatch('clearProvidersFilter').then(getProviders).catch(getProviders)
            }
        },

        loadProvidersFilter() {
            return this.$store.dispatch('loadProvidersFilter').then(loaded_filter => {
                let filter = {}

                for (const key in this.filter) {
                    filter[key] = key in loaded_filter
                        ? typeof this.filter[key] == 'string'
                            ? loaded_filter[key]
                            : {...this.filter[key], ...loaded_filter[key]}
                        : this.filter[key]
                }
                
                this.filter = filter
            }).catch(error => Promise.resolve(error))
        },

        getProviders(page, filter) {
            // console.group('getProviders(page) {')
            // console.log('page', page)

            this.loading = true

            if (!this.providers_features || this.providers_features.length == 0) {
                this.$store.dispatch('getProvidersFeatures')
            }

            filter = {
                ...this.filter,
                ...this.search,
                ...filter
            }
            // console.warn('filter', {...filter})

            let params = {
                'IncludeInactive': true,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize': this.pagination.limit,
                'SearchOptions.SortBy': filter.sort.field,
                'SearchOptions.SortDesc': filter.sort.order === 'desc' ? 1 : 0,
            }

            const word = filter.word.trim()
            // console.log('word', word)

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.word = word
                this.filter.previous = word
            }
            // console.log('params', {...params})

            this.$store.dispatch('api_serviceprovider/FindServiceProvidersPaginated', params).then(response => {
                this.providers_list = response.ServiceProviders

                const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo

                this.pagination.page = PageNumber
                this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.loading = false
            }).catch(error => {
                console.error(error)

                this.providers = []

                this.loading = false
            })

            // console.groupEnd()
        },

        getProviderFeaturesTags(features) {
            // console.group('getProviderFeaturesTags(features) {')
            // console.log('features', features)
            // console.log('this.features', this.features)

            const allAvailableFeatures = this.modules_env
            const allAvailableFeaturesNames = Object.keys(allAvailableFeatures)
            // console.log('allAvailableFeatures', allAvailableFeatures)
            // console.log('allAvailableFeaturesNames', allAvailableFeaturesNames)

            let tags = []

            for (let i = 0, len = allAvailableFeaturesNames.length; i < len; i++) {
                const thisFeatureName = allAvailableFeaturesNames[i]

                if (features.includes(thisFeatureName)) {
                    tags.push( allAvailableFeatures[thisFeatureName].Title )
                }
            }

            // console.log('tags', tags)
            // console.groupEnd()

            return tags
        },



        /**
         * Filtering
         */
        onSearch(search) {
            // console.group('onSearch(search) {')
            // console.log('search', {...search})
            // console.log('search.word.trim()', search.word.trim())
            // console.log('this.filter.word.trim()', this.filter.word.trim())

            if (search.word.trim() != this.filter.word.trim()) {
                this.getProviders(this.pagination.page, { word: search.word })
            }

            // console.groupEnd()
        },

        onSortChange(sort) {
            this.filter.sort = sort
            this.getProviders()
        },

        onPageChange(page) {
            this.getProviders(page)
        },
        
        
        
        /**
         * Provider Details
         */
        onProvidersRowClick(provider) {
            this.$router.push({ name: 'provider', params: { uuid: provider.uuid } })
        },
    },

    computed: {
        ...mapGetters([
            'modules_env',
            'providers_features',
        ]),

        providers() {
            return this.providers_list.map(provider => ({
                uuid: provider.UUID,

                spid: provider.SPID,
                name: provider.Name,
                features: this.getProviderFeaturesTags(provider.Features),
            }))
        },

        allow_create_provider() {
            return process.env.VUE_APP_ALLOW_CREATE_NEW_PROVIDER === 'true'
        },

        allow_remove_provider() {
            return process.env.VUE_APP_ALLOW_REMOVE_PROVIDER === 'true'
        },

        cols() {
            let cols = [
                { key: 'spid',     title: 'SPID',     sort: { field: 'SPID' }, highlight: true },
                { key: 'name',     title: 'Name',     sort: { field: 'Name' }                  },
                { key: 'features', title: 'Features', type: 'tags'                             },
            ]

            if (this.allow_remove_provider) {
                cols.push({ key: 'remove', action: 'remove-service-provider', icon: 'remove', behavior: 'detached' })
            }

            return cols
        },
    },

    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },
}
</script>

<style lang="scss">
.page-providers {
    padding-bottom: 80px;

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .providers-table {
        @include table-cols-width((50px, 150px, 400px, 48px), true, 1);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-providers {
        .app-add-provider {
            margin-top: 30px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-providers {
        .providers-table {
            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }

        .app-add-provider {
            margin-top: 15px;
        }
    }
}
</style>